import appLogic from "./app/logic";
import authLogic from "./auth/logic";
import usersLogic from "./userManagement/logic";
import settingLogic from "./setting/logic";
import spinningWheelLogic from "./spinningWheel/logic";
import surveyLogic from "./survey/logic";
import lookupsLogic from "./lookups/logic";
import questionsLogic from "./questions/logic";
import departmentsLogic from "./department/logic";
import leaderBoardLogic from "./leaderBoard/logic";
import employeeRewardLogic from "./employeeReward/logic";
import dashboardLogic from "./dashboard/logic";
import reportLogic from "./report/logic";

export default [
  ...appLogic,
  ...authLogic,
  ...usersLogic,
  ...settingLogic,
  ...spinningWheelLogic,
  ...surveyLogic,
  ...lookupsLogic,
  ...questionsLogic,
  ...departmentsLogic,
  ...leaderBoardLogic,
  ...employeeRewardLogic,
  ...dashboardLogic,
  ...reportLogic,
];
