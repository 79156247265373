import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { AuthActions } from "src/store/auth/actions";
import { push } from "connected-react-router";

const apiNamespace = "question";
const getQuestionWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_QUESTION_WITH_ID,
});

const addQuestionResponseLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_QUESTION_RESPONSE,
  successCb: (dispatch, payload) => {
    const {score}=payload;
    dispatch(AuthActions.updateUserScore(score))
  }
});

export default [getQuestionWithIdLogic, addQuestionResponseLogic];
