export default function (/**@type {ApisauceInstance} */ api) {
  const getAllEmployeesRewards = () => api.get("EmployeeReward/rewards");
  const addEmployeeReward = (payload) =>
    api.post("EmployeeReward/add-reward", payload);

  return {
    employeeReward: {
      addEmployeeReward,
      getAllEmployeesRewards,
    },
  };
}
