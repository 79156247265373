import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState(
    "employeesRewards",
    {
      metadata: undefined,
    },
    []
  )
};


export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllEmployeesRewards",
    stateName: "employeesRewards",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "addEmployeeReward",
    stateName: "employeeReward",
    async: true,
    isPaginated: false,
  }),
 
});
