import { ActionTypes } from "./actions";
import { bindReducers, defaultState } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("employees", { metadata: undefined }, []),
  allEmployees: [],
  ...defaultState("segments", { metadata: undefined }, []),
  allSegments: [],
  allAnswers: [],
};


export const reducer = createReducer(initialState, {

  ...bindReducers(ActionTypes, {
    action: "getEmployees",
    stateName: "employees",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllEmployees",
    stateName: "allEmployees",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getWhealSegments",
    stateName: "segments",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllWhealSegments",
    stateName: "allSegments",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAnswers",
    stateName: "answers",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllAnswers",
    stateName: "allAnswers",
    async: true,
    isPaginated: false,
  }),

});
