import apisauce from "apisauce";
import config from "../configs";

import dashboardApi from "./dashboard";
import authApi from "./auth";
import usersApi from "./userManagement";
import settingsApi from "./setting";
import spinningWheelApi from "./spinningWheel";
import surveyApi from "./survey";
import lookupsApi from "./lookups";
import questionApi from "./question";
import departmentApi from "./department";
import leaderBoardApi from "./leaderBoard";
import employeeRewardApi from "./EmployeeReward";
import reportApi from "./report";

// const apiURI = config.useLocalApi ? config.devApiRoot : config.apiRoot;
const apiURI = process.env.REACT_APP_BASE_API_URL;
const create = (baseURL = apiURI) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
    },
    timeout: 1200000, //30 sec
  });
  //add access token on each request

  api.addAsyncResponseTransform((response) => async () => {
    if (!response.ok && response.status!==401) {
      const customeError = {
        errors: response.data.errors || [
          { errorMessage: response.data.errorMessage },
        ],
        // title: response.data.title,
        errorMessage: response.data.errorMessage,
        // details: response.data.details,
        // data: response.data.result,
        status: response.status,
        code: response.data.errorCode,
      };
      response.data = customeError;
    }
  });

  api.addAsyncRequestTransform((request) => async () => {
    const token = localStorage.getItem("jwtToken");
    const culture = localStorage.getItem("culture");
    const cultureCode = localStorage.getItem("cultureCode");
    const userIp = localStorage.getItem("userIp");

    request.headers["culture"] = culture || "en";
    request.headers["cultureCode"] = cultureCode || "en-US";
    request.headers["userIp"] = userIp || "";

    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete request.headers["Authorization"];
    }
  });

  const auth = authApi(api);
  const users = usersApi(api);
  const settings = settingsApi(api);
  const spinningWheel = spinningWheelApi(api);
  const lookups = lookupsApi(api);
  const survey = surveyApi(api);
  const question = questionApi(api);
  const department = departmentApi(api);
  const leaderBoard = leaderBoardApi(api);
  const employeeReward = employeeRewardApi(api);
  const dashboard = dashboardApi(api);
  const report = reportApi(api);

  // const transactions = transactionsApi(api);
  return {
    setHeader: api.setHeader,
    setHeaders: api.setHeaders,
    ...auth,
    ...users,
    ...settings,
    ...spinningWheel,
    ...survey,
    ...lookups,
    ...question,
    ...department,
    ...leaderBoard,
    ...dashboard,
    ...employeeReward,
    ...report,
  };
};

export default { create };
