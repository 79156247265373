import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllEmployeesRewards: { args: ["payload"], meta: { async: true } },
    addEmployeeReward: { args: ["payload"], meta: { async: true } },
 
  },
  {
    prefix: "@app/employeeReward/",
  }
);
export const ActionTypes = Types;
export default Creators;
