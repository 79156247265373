import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "leaderBoard";

const getLeaderBoardLogic = logic(apiNamespace, 
    {
         actionName: ActionTypes.GET_LEADER_BOARD, 
        showErrorMessage: true, showSuccessMessage: false,
    });

export default [getLeaderBoardLogic];
