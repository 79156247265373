import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getEmployees: { args: ["payload"], meta: { async: true } },
    getAllEmployees: { args: ["payload"], meta: { async: true } },
    getWhealSegments: { args: ["payload"], meta: { async: true } },
    getAllWhealSegments: { args: ["payload"], meta: { async: true } },
    getAnswers: { args: ["payload"], meta: { async: true } },
    getAllAnswers: { args: ["payload"], meta: { async: true } },
  },
  {
    prefix: "@app/report/",
  }
);
export const ActionTypes = Types;
export default Creators;
