import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  surveyAnalyses: [],
};

export const reducer = createReducer(initialState, {
  //**Categories
  ...bindReducers(ActionTypes, {
    action: "getGeneralInfo",
    stateName: "generalInfo",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getSurveyAnalyses",
    stateName: "surveyAnalyses",
    async: true,
    isPaginated: false,
  }),
});
