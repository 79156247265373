import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState(
    "leaderBoard",
    {
      metadata: undefined,
    },
      {}
  ),
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getLeaderBoard",
    stateName: "leaderBoard",
    async: true,
    isPaginated: false,
  }),
});
