export default function (/**@type {ApisauceInstance} */ api) {
  const getEmployees = (payload) =>
    api.get(`report/employee-interactions`, payload);
  const getAllEmployees = (payload) =>
    api.get(`report/employee-interactions-all`, payload);
  const getWhealSegments = (payload) =>
    api.get(`report/wheel-segments`, payload);
  const getAllWhealSegments = () => api.get(`report/wheel-segments-all`);
  const getAnswers = (payload) => api.get(`report/answers`, payload);
  const getAllAnswers = (payload) => api.get(`report/answers-all`,payload);

  return {
    report: {
      getEmployees,
      getAllEmployees,
      getWhealSegments,
      getAllWhealSegments,
      getAnswers,
      getAllAnswers,
    },
  };
}
