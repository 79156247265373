export default function (/**@type {ApisauceInstance} */ api) {
  const getQuestionWithId = (id) => api.get(`questions/${id}`);
  const addQuestionResponse = (payload) => api.post("survey/add-survey-response", payload);
  return {
    question: {
      getQuestionWithId,
      addQuestionResponse
    },
  };
}
