export default function (/**@type {ApisauceInstance} */ api) {
  const getUsers = (paging) => api.get("user-management/users", paging);
  const getPermissions = () => api.get("user-management/permissions");
  const createUser = (payload) => api.post("user-management/users", payload);
  const createUsers = (payload) => api.post("user-management/add-users", payload);
  const createAppUser = (payload) => api.post("user-management/users/app-user", payload);
  const getUser = (id) => api.get(`user-management/users/${id}`);
  const getRoles = (paging) => api.get("user-management/roles", paging);
  const deleteUser = (id) => api.delete(`user-management/users/delete-user/${id}`);
  const createRole = (payload) => api.post("user-management/roles/add-role", payload);
  const editUser = ({ payload, id }) => api.put(`user-management/users/edit-user/${id}`, payload);
  const deleteRole = (id) => api.delete(`user-management/roles/delete-role/${id}`);
  const editRole = ({ payload, id }) => api.put(`user-management/roles/edit-role/${id}`, payload);
  const getRole = (id) => api.get(`user-management/roles/${id}`);
  const getEmployees=(paging)=>api.get("user-management/employees",paging)
  const getEmployee=(id)=>api.get(`user-management/employees/${id}`)
  const deleteEmployee=(id)=>api.delete(`user-management/employees/${id}`)
  const addEmployee=(payload)=>api.post(`user-management/employees`,payload)
  const editEmployee=({payload,id})=>api.put(`user-management/employees/${id}`,payload)
  return {
    userManegment: {
      getUsers,
      getUser,
      createUser,
      createUsers,
      createAppUser,
      deleteUser,
      getRoles,
      editUser,
      createRole,
      getPermissions,
      deleteRole,
      editRole,
      getRole,
      getEmployees,
      getEmployee,
      deleteEmployee,
      addEmployee,
      editEmployee,
    }
  };
}

