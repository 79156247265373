import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState(
    "surveys",
    {
      metadata: undefined,
      selectedSurvey: undefined,
      openDialog: false,
      openDeleteDialog: false,
    },
    []
  ),

  userSurveys: [],
  ...defaultState(
    "survey",
    {
      metadata: undefined,
      selectedSurvey: undefined,
      openDialog: false,
      openDeleteDialog: false,
    },
    {}
  ),
};

const surveyEditedSuccess = (state, draft, payload) => {
  //debugger;
  const surveys = state.surveys || [];
  var surveyIndex = surveys.findIndex((u) => u.id === payload.id);
  draft.surveys[surveyIndex] = payload;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

const surveyDeleteSuccess = (state, draft, payload) => {
  debugger;
  const surveys = state.surveys || [];
  draft.surveys = surveys.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};

const addSurveyQuestionSuccess = (state, draft, payload) => {
  draft.survey.questions = [...draft.survey.questions, payload];
};
const editSurveyQuestionSuccess = (state, draft, payload) => {
  const questionIndex =
    draft?.survey?.questions?.findIndex((a) => a.id === payload.id) || -1;
  if (questionIndex > -1) draft.survey.questions[questionIndex] = payload;
};


const removeSurveyQuestionSuccess = (state, draft, payload) => {
  debugger;
  const questionIndex = draft?.survey?.questions?.findIndex(
    (a) => a.id === payload.id
  );
  draft.survey.questions.splice(questionIndex, 1);
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "saveSurveyInformation",
    stateName: "survey",
    async: false,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getSurveyWithId",
    stateName: "survey",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getSurveys",
    stateName: "surveys",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getUserSurveys",
    stateName: "userSurveys",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getUserSurveysWithId",
    stateName: "userSurveysWithId",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "createSurvey",
    stateName: "survey",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "addSurveyResponse",
    stateName: "surveyResponse",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "editSurvey",
    stateName: "survey",
    async: true,
    isPaginated: false,
    successCb: surveyEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "addSurveyQuestion",
    stateName: "surveyQuestion",
    async: true,
    isPaginated: false,
    successCb: addSurveyQuestionSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editSurveyQuestion",
    stateName: "surveyQuestion",
    async: true,
    isPaginated: false,
    successCb: editSurveyQuestionSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "removeSurveyQuestion",
    stateName: "surveyQuestion",
    async: true,
    isPaginated: false,
    successCb: removeSurveyQuestionSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteSurvey",
    stateName: "survey",
    async: true,
    isPaginated: false,
    successCb: surveyDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "setSurvey",
    stateName: "survey",
    async: false,
    isPaginated: false,
  }),
});
