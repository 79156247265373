import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getDepartments: { args: ["payload"], meta: { async: true } },
    createDepartment: { args: ["payload"], meta: { async: true } },
    editDepartment: { args: ["payload"], meta: { async: true } },
    deleteDepartment: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/departments/",
  }
);
export const ActionTypes = Types;
export default Creators;
export const DepartmentActions=Creators;
