import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
     getAllQuestionsTypes: { args: ["payload"], meta: { async: true } },
    
  },
  {
    prefix: "@app/lookups/"
  }
);
export const ActionTypes = Types;
export default Creators;