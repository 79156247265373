import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import layoutReducer from "./layout/reducer";
import navbarReducer from "./navbar/reducer";

import { authReducer } from "./auth/reducer";


const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: [
    "router",
    "layout",
    "userManegment",
    "spinningWheel",
    "survey",
    "lookups",
    "question",
    "department",
    "report",

  ],
};

export default function createReducer(asyncReducers, history) {
  return persistReducer(
    rootPersistConfig,
    combineReducers({
      ...asyncReducers,
      router: connectRouter(history),
      app: require("./app/reducer").reducer,
      auth: authReducer,
      layout: layoutReducer,
      navbar: navbarReducer,
      userManegment: require("./userManagement/reducer").reducer,
      settings: require("./setting/reducer").reducer,
      spinningWheel: require("./spinningWheel/reducer").reducer,
      survey: require("./survey/reducer").reducer,
      lookups: require("./lookups/reducer").reducer,
      question: require("./questions/reducer").reducer,
      department: require("./department/reducer").reducer,
      leaderBoard: require("./leaderBoard/reducer").reducer,
      employeeReward: require("./employeeReward/reducer").reducer,
      dashboard: require("./dashboard/reducer").reducer,
      report: require("./report/reducer").reducer,
    })
  );
}
