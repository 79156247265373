export default function (/**@type {ApisauceInstance} */ api) {
    const getGeneralInfo = () => api.get(`dashboard/general-info`);
    const getSurveyAnalyses = () => api.get(`dashboard/survey-analyses`);
    
    return {
      dashboard: {
        getGeneralInfo,
        getSurveyAnalyses,
      },
    };
  }