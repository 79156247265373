import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";

const apiNamespace = "report";

const getEmployeesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_EMPLOYEES,
});

const getAllEmployeesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_EMPLOYEES,
});

const getWhealSegmentsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_WHEAL_SEGMENTS,
});
const getAllWhealSegmentsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_WHEAL_SEGMENTS,
});

const getAnswersLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ANSWERS,
});
const getAllAnswersLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_ANSWERS,
});


export default [
  getEmployeesLogic,
  getAllEmployeesLogic,
  getWhealSegmentsLogic,
  getAllWhealSegmentsLogic,
  getAnswersLogic,
  getAllAnswersLogic,
];
