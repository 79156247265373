import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState(
    "question",
    {
      metadata: undefined,
    },
    {}
  ),
  sweetAlertOpened: false,
  spinningWheelOpened: false,
  spinningWheelId: "",
  spinningWheelMatrixId: "",
};

const questionResponseAddedd = (state, draft, payload) => {
  draft.question = {
    ...state.question,
    answered: true,
    // score:payload.score,
    correct: payload.correct,
  };

  if (payload.openWheel) {
    draft.spinningWheelOpened = true;
  } else {
    draft.sweetAlertOpened = true;
  }
  draft.spinningWheelId=payload.spinningWheelId;
  draft.spinningWheelMatrixId=payload.spinningWheelMatrixId;
};

const toggleSweetAlert = (state, draft, payload) => {
  draft.sweetAlertOpened = payload;
};
const toggleٍٍSpinningWheel = (state, draft, payload) => {
  draft.spinningWheelOpened = payload;
};
const resretModals = (state, draft, payload) => {
  draft.spinningWheelOpened = false;
  draft.sweetAlertOpened = false;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getQuestionWithId",
    stateName: "question",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "addQuestionResponse",
    stateName: "questionResponse",
    async: true,
    isPaginated: false,
    successCb: questionResponseAddedd,
  }),
  ...bindReducers(ActionTypes, {
    action: "toggleSweetAlert",
    stateName: "sweetAlertOpened",
    async: false,
    isPaginated: false,
    successCb: toggleSweetAlert,
  }),
  ...bindReducers(ActionTypes, {
    action: "toggleٍٍSpinningWheel",
    stateName: "spinningWheelOpened",
    async: false,
    isPaginated: false,
    successCb: toggleٍٍSpinningWheel,
  }),

  ...bindReducers(ActionTypes, {
    action: "resretModals",
    stateName: "modals",
    async: false,
    isPaginated: false,
    successCb: resretModals,
  }),
});
