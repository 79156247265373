import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getUsers: { args: ["payload"], meta: { async: true } },
    getUser: { args: ["payload"], meta: { async: true } },
    getPermissions: { args: [], meta: { async: true } },
    createUser: { args: ["payload"], meta: { async: true } },
    createUsers: { args: ["payload"], meta: { async: true } },
    createAppUser: { args: ["payload"], meta: { async: true } },
    deleteUser: { args: ["payload"], meta: { async: true } },
    setUser: { args: ["payload"], meta: { async: false } },
    createRole: { args: ["payload"], meta: { async: true } },
    getRoles: { args: ["payload"], meta: { async: true } },
    getRole: { args: ["payload"], meta: { async: true } },
    editUser: { args: ["payload"], meta: { async: true } },
    editRole: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
    deleteRole: { args: ["payload"], meta: { async: true } },
    
    getEmployees: { args: ["payload"], meta: { async: true } },
    getEmployee: { args: ["payload"], meta: { async: true } },
    deleteEmployee: { args: ["payload"], meta: { async: true } },
    addEmployee: { args: ["payload"], meta: { async: true } },
    editEmployee: { args: ["payload"], meta: { async: true } },
    setEmployee: { args: ["payload"], meta: { async: false } },

  },
  {
    prefix: "@app/users/"
  }
);
export const ActionTypes = Types;
export default Creators;