
import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "department";
const getDepartmentsLogic = logic(apiNamespace, {actionName:ActionTypes.GET_DEPARTMENTS});
const createDepartmentLogic = logic(apiNamespace, {actionName:ActionTypes.CREATE_DEPARTMENT});
const editDepartmentLogic = logic(apiNamespace, { actionName: ActionTypes.EDIT_DEPARTMENT});
const deleteDepartmentLogic=logic(apiNamespace,{actionName:ActionTypes.DELETE_DEPARTMENT});

export default [getDepartmentsLogic,createDepartmentLogic,editDepartmentLogic,deleteDepartmentLogic];