import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getQuestionWithId: { args: ["payload"], meta: { async: true } },
    addQuestionResponse: { args: ["payload"], meta: { async: true } },
    toggleSweetAlert: { args: ["payload"], meta: { async: false } },
    toggleٍٍSpinningWheel: { args: ["payload"], meta: { async: false } },
    resretModals: { args: ["payload"], meta: { async: false } },
  },
  {
    prefix: "@app/question/",
  }
);
export const ActionTypes = Types;
export default Creators;
