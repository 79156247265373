export default function(/**@type {ApisauceInstance} */ api) {

    const getDepartments = (paging) => api.get("department", paging);
    const deleteDepartment = (id) => api.delete(`department/delete-department/${id}`);
    const editDepartment = ({ payload, id }) => api.put(`department/edit-department/${id}`, payload);
    const createDepartment = (payload) => api.post("department/add-department", payload);
  
    return {
      department: {
        getDepartments,
        deleteDepartment,
        editDepartment,
        createDepartment
      },
    };
  }
  