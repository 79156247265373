import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "lookups";

const getAllQuestionsTypesLogic = logic(apiNamespace, 
    {
         actionName: ActionTypes.GET_ALL_QUESTIONS_TYPES, 
        showErrorMessage: true, showSuccessMessage: false,
    });

export default [getAllQuestionsTypesLogic];
