import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "dashboard";

// categories

const getGeneralInfoLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_GENERAL_INFO,
});

const getSurveyAnalysesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SURVEY_ANALYSES,
});

export default [
  getGeneralInfoLogic,
  getSurveyAnalysesLogic,
];
