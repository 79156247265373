import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState(
    "questionsTypes",
    {
      metadata: undefined,
    },
    []
  ),
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllQuestionsTypes",
    stateName: "questionsTypes",
    async: true,
    isPaginated: false,
  }),
});
