import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState(
    "departments",
    {
      metadata: undefined,
      selectedSurvey: undefined,
      openDialog: false,
      openDeleteDialog: false,
    },
    []
  ),
  ...defaultState(
    "department",
    {
      metadata: undefined,
      selectedSurvey: undefined,
      openDialog: false,
      openDeleteDialog: false,
    },
    {}
  ),
};


const departmentEditedSuccess = (state, draft, payload) => {
  const departments = state.departments || [];
  var departmentIndex = departments.findIndex((u) => u.id === payload.id);
  draft.departments[departmentIndex] = payload;
};

const departmentCreatedSuccess = (state, draft, payload) => {
  const departments = state.departments || [];
  draft.departments = [...departments, payload];

  draft.openDialog = false;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

const departmentDeleteSuccess = (state, draft, payload) => {
  // debugger;
  const departments = state.departments || [];
  draft.departments = departments.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};


export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getDepartments",
    stateName: "departments",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "createDepartment",
    stateName: "department",
    async: true,
    isPaginated: false,
    successCb:departmentCreatedSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "editDepartment",
    stateName: "department",
    async: true,
    isPaginated: false,
    successCb: departmentEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteDepartment",
    stateName: "department",
    async: true,
    isPaginated: false,
    successCb: departmentDeleteSuccess,
  }),
});
