import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getLeaderBoard: { args: ["payload"], meta: { async: true } },
    
  },
  {
    prefix: "@app/leaderBoard/"
  }
);
export const ActionTypes = Types;
export default Creators;