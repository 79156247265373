import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "employeeReward";
const getAllEmployeesRewards = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_EMPLOYEES_REWARDS,
});

const addEmployeeReward = logic(apiNamespace, {
  actionName: ActionTypes.ADD_EMPLOYEE_REWARD,
});

export default [getAllEmployeesRewards, addEmployeeReward];
