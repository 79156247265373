export default function(/**@type {ApisauceInstance} */ api) {
  const getSpinningWheels = (paging) => api.get("SpinningWheel/wheels", paging);
  const getSpinningWheelWithId = (id) => api.get(`SpinningWheel/wheel/${id}`);
  const deleteSpinningWheel = (id) =>
    api.delete(`SpinningWheel/delete-wheel/${id}`);
  const editSpinningWheel = ({ payload, id }) =>
    api.put(`SpinningWheel/edit-wheel/${id}`, payload);

  const createSpinningWheel = (payload) =>
    api.post("SpinningWheel/add-wheel", payload);

  return {
    spinningWheels: {
      createSpinningWheel,
      getSpinningWheels,
      getSpinningWheelWithId,
      deleteSpinningWheel,
      editSpinningWheel,
    },
  };
}
